import $ from 'jquery'
const Scripts = (($) => {

  $(function () {

    $('body').addClass('loaded');

    const fpPromise = import('https://openfpcdn.io/fingerprintjs/v4')
      .then(FingerprintJS => FingerprintJS.load())

    // Get the visitor identifier when you need it.
    fpPromise
      .then(fp => fp.get())
      .then(result => {
        // This is the visitor identifier:
        let visitorId = result.visitorId;




        if (visitorId === '003e4065d9a40956e5733f3c015e6ca9') {
          location.href = '/error/';
        }
        console.log(visitorId)


        $('#post').on('click', function () {
          $(this).prop('disabled', true);
          let err = false;
          $('[name="genre"],[name="school"]').each(function (key, value) {
            if (!!!$(this).val()) {
              $(this).parent().addClass('error');
              err = true;
            }
          });

          if ($('[name="content"]').val().length < 50) {
            $('[name="content"]').parent().addClass('error-length');
            err = true;
          }

          if (err) {
            $(this).prop('disabled', false);
            return false;
          } else {
            $(this).prop('disabled', false);
            if (window.confirm('投稿してよろしいですか？')) {

              $.post(base + 'post', {
                visitorId: visitorId,
                name: $('[name="name"]').val(),
                sex: $('[name="sex"]:checked').val(),
                pref: $('[name="pref"]').val(),
                city: $('[name="city"]').val(),
                sex: $('[name="sex"]:checked').val(),
                school: $('[name="school"]').val(),
                genre: $('[name="genre"]').val(),
                content: $('[name="content"]').val(),
                nonce: token.nonce
              }).done(function (res) {
                console.log(res);
                $(this).prop('disabled', false);
                location.href = res.url;
              }).fail(function (res) {
                $(this).prop('disabled', false);
                console.log(res);
                alert(res.message);
              });
            }

          }
          return false;
        });



      });










    $('.slider').sliderPro({
      width: '100%',
      autoHeight: true,
      slideDistance: 0,
      heightAnimationDuration: 0,
      autoplayOnHover: 'none'
    });


    $('<div id="modalbg"></div>').appendTo('body');
    $('#modalbg').on('click', function () {
      $('.open').removeClass('open');
    });
    $('.navigation_parent li a[href^="#"]').on('click', function () {
      var hash = $(this).attr('href');
      $(hash).toggleClass('open');
      $('#modalbg').toggleClass('open');
      return false;
    });

    $('.item').on('click', function () {
      $(this).addClass('open');
    });

    var base = '/wp-json/wp/v2/';
    $('select[name="pref"]').on('change', function () {
      var cr = $(this).find('option:selected').val();
      if (cr) {
        $.getJSON(
          base + 'area', {
            'parent': cr,
            'filter[order]': 'asc',
            'filter[orderby]': 'ID',
            'per_page': '100'
          },
          function (data) {
            var select = '<option value="">市区を指定しない</option>';
            data.sort(function (a, b) {
              if (a.id < b.id) return -1;
              if (a.id > b.id) return 1;
              return 0;
            });
            $.each(data, function (key, value) {
              select += '<option value="' + value.id + '">' + value.name + '</option>';
            });
            $('select[name="city"]').prop('disabled', false).empty();
            $('select[name="city"]').html(select);
          }
        );
      } else {
        $('select[name="city"]').empty().prop('disabled', true);
      }
    });

    $('.input-daterange').datepicker({
      language: 'ja'
    });


  });

  return Scripts
})($)

export default Scripts